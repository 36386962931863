<template>
  <div>
    <HeaderPanel
      title="Transaction Code"
      :filter="filter"
      placeholder="Search Name"
      @search="getData"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      routerPath="/transaction-code/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
        @handleCheckAll="handleCheckAll"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="getData"
      placeholder="Search Staff ID, Name"
      @clearFilter="clearFilter"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <label class="label-text"> Valid From </label>
        <div class="input-container">
          <datetime
            :input-style="styleDatetime"
            placeholder="Please Select Date"
            type="datetime"
            v-model="filter.valid_from"
            format="dd/MM/yyyy HH:mm:ss"
            value-zone="Asia/Bangkok"
            ref="valid_from"
          ></datetime>
          <div
            class="icon-primary text-right"
            @click="$refs.valid_from.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <!-- <div v-if="$v.form.valid_from">
              <span
                class="text-error"
                v-if="!$v.form.valid_from.required && chkClickForm"
                >Please select date time.</span
              >
            </div> -->

        <div class="my-3">
          <label class="label-text"> Valid To </label>
          <div class="input-container">
            <datetime
              :input-style="styleDatetime"
              placeholder="Please Select Date"
              type="datetime"
              v-model="filter.valid_to"
              format="dd/MM/yyyy HH:mm:ss"
              :min-datetime="filter.valid_from"
              value-zone="Asia/Bangkok"
              ref="valid_to"
            ></datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.valid_to.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'pointer color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import Table from "./Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        page: 1,
        take: 10,
        search: "",
        valid_from: "",
        valid_to: "",
        status: [],
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "quantity",
          label: "Use / Quantity",
        },
        {
          key: "valid_from",
          label: "Valid From",
        },
        {
          key: "valid_to",
          label: "Valid To",
        },

        {
          key: "active",
          label: "Status",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      items: [],
      styleDatetime: {
        width: "150%",
        border: "none",
      },
    };
  },

  created() {
    this.getData();
  },
  methods: {
    handleCheckAll(val) {
      this.items.map((el) => el.isChecked == val);
      for (const items of this.items) {
        items.isChecked = val;
      }

      this.items = [...this.items];
    },
    getData() {
      let payload = { ...this.filter };
      payload.valid_from = payload.valid_from
        ? payload.valid_from
        : "01/01/0001 00:00:00";
      payload.valid_to = payload.valid_to
        ? payload.valid_to
        : "01/01/0001 00:00:00";
      this.isBusy = true;

      this.$Axios
        .post(`${process.env.VUE_APP_API}/TransactionCode/list`, payload)
        .then((res) => {
          if (res.data.result) {
            this.rows = res.data.detail.count;
            this.items = res.data.detail.data;
            this.isBusy = false;
          }
        });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    filterPage(filter) {
      this.filter = filter;
      this.getData();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
      };
    },
  },
};
</script>
